.btn {
  cursor: pointer;
  border: none;
  border-radius: 11px;
  width: 272px;
  height: 55px;
  color: #fff;
  font-weight: 900;
  background-color: var(--main-yellow);
  box-shadow: -1px 3px 6px rgba(0, 0, 0, 0.55);
  font-size: 16px;
  line-height: 24px;
  transition: all 0.2s ease-in;

  &_outline {
    color: var(--main-yellow);
    border: 1px solid var(--main-yellow);
    background: #111a1b;
  }

  &_secondary {
    background-color: var(--orange);
  }

  &_brown-text {
    color: #7b4e2b;
  }

  &:hover {
    box-shadow: 0px 0px 7px rgba(244, 205, 58, 0.5);
  }

  &_disabled {
    cursor: auto;
    &:hover {
      box-shadow: none;
    }
  }
}
