@import '../../styles/mixins';

.future {
  background-color: var(--secondary-bg);

  &__wrapper {
    padding-top: 197px;
    padding-bottom: 197px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include tablet {
      flex-wrap: wrap;
      padding-top: 85px;
      padding-bottom: 100px;
    }
  }

  &__cards-wrapper {
    max-width: 447px;
    margin-right: 90px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    z-index: 1;
    position: relative;

    @include tablet {
      margin-right: 0;
    }

    @include mobile {
      column-gap: 10px;
    }

    &::before {
      content: url('../../assets/img/wheat-pack.png');
      position: absolute;
      bottom: -38%;
      left: -91%;
      z-index: -1;

      @include tablet {
        bottom: -30%;
      }
    }
  }

  &__cards-column {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    row-gap: 30px;

    &_offset {
      position: relative;
      top: 75px;
    }
  }

  &__card {
    width: 210px;
    padding: 24px 29px;
    background: rgba(26, 31, 29, 0.52);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @include tablet {
      width: 156px;
      padding: 7px;
      backdrop-filter: blur(34px);
    }

    & h5 {
      margin: 0;
      margin-top: 16px;
      font-family: 'Passero One', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 33px;
      line-height: 50px;
      color: var(--main-yellow);
    }

    & p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 26px;
      color: #e8e8e8;
    }
  }

  &__descr {
    max-width: 544px;

    @include tablet {
      margin-top: 155px;
    }

    & h2 {
      margin-top: 19px;
    }

    & p {
      margin-top: 19px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      color: var(--text-secondary);

      @include tablet {
        font-size: 14px;
      }
    }
  }
}
