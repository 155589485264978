@import '../../styles/mixins';

.footer {
  background-color: var(--secondary-bg);
  padding: 118px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include tablet {
    padding: 0 16px;
    padding-top: 118px;
    padding-bottom: 65px;
  }

  &__contact {
    margin-top: 36px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 16px;
    color: #e8e8e8;

    @include tablet {
      text-align: center;
      line-height: 33px;
    }
  }
}
