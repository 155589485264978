@import '../../styles/mixins';

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;

  &__logo {
    cursor: pointer;
    width: 77px;
    height: 40px;
  }

  &__menu {
    @include tablet {
      display: none;
    }
  }

  &__item-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & li {
      margin-right: 50px;
      text-align: center;
      cursor: pointer;

      &:hover {
        color: #fff;
      }

      @include tablet {
        margin-right: 0;
        padding: 5px;
        font-size: 35px;
        width: 100%;
      }
    }

    @include tablet {
      flex-direction: column;
    }
  }

  &__social {
    display: flex;
    justify-content: center;
    align-items: center;

    & a {
      margin-left: 16px;
    }
  }

  &__hamburger {
    border: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
    @include tablet {
      display: block;
    }
  }

  &__mobile-menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--secondary-bg);
    z-index: 99;
    padding: 30px 0;
    transform: translateX(0);
    transition: transform 0.3s ease;
    @include tablet {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    & ul {
      margin-top: 50px;
      width: 100%;
    }

    &_hidden {
      transform: translateX(-100%);
    }
  }

  &__mobile-menu-close {
    background: none;
    border: 1px solid gray;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    color: gray;
    font-size: 30px;
    line-height: 50px;
  }
}
