@import '../../styles//mixins';

.roadmap {
  padding-top: 180px;
  padding-bottom: 180px;

  @include tablet {
    padding-top: 90px;
    padding-bottom: 110px;
  }

  & h2 {
    text-align: center;
  }

  &__text {
    max-width: 792px;
    margin: 0 auto;
    margin-top: 25px;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: var(--text-secondary);

    @include tablet {
      font-size: 14px;
    }
  }

  &__items-wrapper {
    margin-top: 60px;
    display: grid;
    grid-template-rows: repeat(8, 1fr);
    row-gap: 20px;

    @include tablet {
      display: none;
    }
  }

  &__item {
    background-color: var(--secondary-bg);
    border-radius: 40px;
    padding: 18px;
    display: flex;
    align-items: center;

    @include tablet {
      display: flex !important;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      text-align: center;
      position: relative;
      min-height: 360px;
    }
  }

  &__item-img {
    width: 99px;
    height: 99px;
    margin-right: 18px;
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.25));

    @include tablet {
      margin: 0 auto;
    }
  }

  &__item-descr {
    width: 80%;

    @include tablet {
      width: auto;
    }
    & h6 {
      margin: 0;
      font-family: 'Passero One', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 23px;
      line-height: 22px;
      text-transform: uppercase;
      color: var(--main-yellow);
      @include tablet {
        margin-top: 15px;
      }
    }

    & p {
      margin-top: 5px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 26px;
      color: var(--text-secondary);

      @include tablet {
        margin-top: 15px;
      }
    }
  }

  &__item-percentage {
    margin-left: auto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: var(--main-yellow);

    @include tablet {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  &__mobile-slider {
    display: none;
    margin-top: 35px;
    width: calc(100% + 30px);
    margin-left: -15px;
    @include tablet {
      display: block;
    }
  }
}

.slick-slide {
  padding: 0 10px;
}
