:root {
  // ===== COLORS =========
  --main-text: #9a9a9a;
  --main-yellow: #f4cd3a;
  --secondary-bg: #252422;
  --green-text: #64f4ab;
  --text-secondary: #ecf2fd;
  --orange:#D68316;

  // ==== WIDTH =========
  --container-width: 1170px;
}
