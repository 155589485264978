@import '../../styles/mixins';

.join {
  padding-top: 135px;
  padding-bottom: 100px;

  @include tablet {
    padding-top: 40px;
  }

  &__content {
    background: url('../../assets/img/join-bg.png') center no-repeat;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
    border-radius: 17px;
    min-height: 457px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;

    @include tablet {
      width: calc(100% + 30px);
      margin-left: -15px;
      height: 700px;
      padding: 0 15px;
    }

    & h2 {
      text-transform: capitalize;
    }

    & p {
      max-width: 744px;
      margin-top: 25px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: var(--text-secondary);
      text-align: center;
    }

    & button {
      margin-top: 25px;
    }
  }
}
