@import '../../styles/mixins';

.faqs {
  padding-top: 123px;
  padding-bottom: 123px;

  @include tablet {
    padding-top: 75px;
    padding-bottom: 100px;
  }

  &__descr {
    text-align: center;
    max-width: 792px;
    margin: 0 auto;

    & p {
      margin-top: 34px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 26px;
      color: var(--text-secondary);

      @include tablet {
        margin-top: 12px;
      }
    }
  }

  &__list-wrapper {
    margin-top: 42px;
    border-radius: 20px;
    background-color: var(--secondary-bg);

    @include tablet {
      width: calc(100% + 30px);
      margin-left: -15px;
    }
  }

  &__list-item {
    &:not(:last-child) {
      border-bottom: 1px solid #5b5028;
    }
  }

  &__list-header {
    display: flex;
    cursor: pointer;
    padding: 27px 40px;

    @include tablet {
      padding: 27px 20px;
    }

    & h4 {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #fff;
    }

    & span {
      margin-left: auto;
    }

    &_active {
      & h4 {
        color: #f4cd3a;
      }
    }
  }

  &__list-content {
    padding: 19px 40px;
    border-top: 1px solid #312e23;
    max-height: 500px;
    overflow: hidden;
    transition: max-height 2s ease-out;

    @include tablet {
      padding: 30px 20px;
    }

    &_hidden {
      position: absolute;
      max-height: 0;
      transform: scaleY(0);
    }

    & p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 154.3%;
      color: #ffffff;
      white-space: pre-wrap;
    }
  }
}

.plus {
  min-width: 25px;
  min-height: 25px;
  width: 25px;
  height: 25px;
  background: #f4cd3a;
  color: #fff;
  border-radius: 6px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.minus {
  min-width: 25px;
  min-height: 25px;
  width: 25px;
  height: 25px;
  background: #5c5c5c;
  color: #f4cd3a;
  border-radius: 6px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
